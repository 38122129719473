import { ReactElement } from 'react';
import { ProductBoxes as CoreProductBoxes } from '@client/content/components/ModularPage/Modules/ProductBoxListV2/ProductBoxes/ProductBoxes';
import { Props } from '@client/content/components/ModularPage/Modules/ProductBoxListV2/ProductBoxes/ProductBoxes.types';
import { useTranslate } from '@client/i18n/hooks/useTranslate';

export const ProductBoxes = (props: Props): ReactElement => {
  const t = useTranslate();
  const priceWithVatLabel = t(
    'Comparison Price<br />(incl. VAT)',
    {},
    'Compare Price (Vergleichspreis) with VAT',
  );

  return <CoreProductBoxes {...props} priceWithVatLabel={priceWithVatLabel} />;
};
