export const META = [
  {
    name: 'msapplication-config',
    content: '/assets/favicons/browserconfig.xml',
  },
  {
    name: 'msapplication-TileColor',
    content: '#e2001a',
  },
  {
    name: 'theme-color',
    content: '#ffffff',
  },
];

export const LINK = [
  {
    rel: 'apple-touch-icon',
    sizes: '180x180',
    href: '/assets/favicons/apple-touch-icon.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '32x32',
    href: '/assets/favicons/favicon-32x32.png',
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '16x16',
    href: '/assets/favicons/favicon-16x16.png',
  },
  {
    rel: 'manifest',
    href: '/assets/favicons/site.webmanifest',
  },
  {
    rel: 'mask-icon',
    href: '/assets/favicons/safari-pinned-tab.svg',
    color: '#e2001a',
  },
  {
    rel: 'shortcut icon',
    href: '/assets/favicons/favicon.ico',
  },
];
