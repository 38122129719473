import { ReactElement } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { AppContainer as CoreApp } from '@client/app/components/App/AppContainer';
import { useActiveLanguage } from '@client/i18n/hooks';
import { ROUTES } from '@client/routes/components/Router/routes';

export const App = (): ReactElement => {
  const activeLanguage = useActiveLanguage();
  const { pathname } = useLocation();
  const isProductPageRoute = matchPath(pathname, {
    path: ROUTES.product.paths[activeLanguage],
    exact: true,
  });

  return <CoreApp isProductPageRoute={!!isProductPageRoute} />;
};
